// Importando funciones de funciones.js
import { 
    llenarCardsBlog, llenarPageBlog, cargarPrecios, mostrarDetallesBlog, getParameterByName, iniciaSlider,
    navBackground, next_fondo, actBanner, nextBanner, updateActiveClass, llena_bannerspub, updateMessage, cycleMessages,
    reproduce_video_youtube, reproduce_testimonio, info_Software
} from './funciones.js';    

(function() {
    'use strict';


    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false; 
    var resEnPantalla = 0;
    var idSoftware = 2;

    let heros = [];      

    // Obtener todos los enlaces del menú en un array
    const menuLinks = Array.from(document.querySelectorAll('.nav_links'));    

    const messages = [
        {title: "ADMINISTRACIÓN"},
        {title: "INVENTARIOS"},
        {title: "NEGOCIACIÓN CON PROVEEDORES"},
        {title: "SEGURIDAD ALIMENTARIA"},
        {title: "MEJORA LA EXPERIENCIA DEL CLIENTE"},
        {title: "CREACIÓN DE PROGRAMAS DE LEALTAD"},        
        {title: "IMPLEMENTA EL SERVICIO A DOMICILIO"},        
        {title: "ESTRATEGIAS DE PRECIOS"},        
        {title: "COMUNICACIÓN Y ATENCIÓN AL CLIENTE"},        
        {title: "MARKETING"},        
        {title: "FINANZAS"},        
        {title: "ASPECTOS LEGALES Y FISCALES"},
        {title: "TECNOLOGÍA"},        
        {title: "INTELIGENCIA EN LOS NEGOCIOS"},        
        {title: "FORMACIÓN Y DESARROLLO DE EQUIPOS"},
        {title: "DESARROLLO Y FORMACIÓN DEL PERSONAL"},
        {title: "VENTAS Y RETENCIÓN DE CLIENTES"}, 
        {title: "GESTIÓN DE LA REPUTACIÓN EN LÍNEA"},        
        {title: "REDES SOCIALES"},        
        {title: "BRANDING"},        
        {title: "Y MUCHOS CURSOS MÁS"}
    ];

    let currentIndex = 0;
    const titleElement = document.getElementById('message-title');
    const descriptionElement = document.getElementById('message-description');
    const containerElement = document.getElementById('message-container');
    const indicators = document.querySelectorAll('#indicators span');


    document.addEventListener('DOMContentLoaded', function(){

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA PRINCIPAL
        const div_blog = document.getElementById('contenedor-cards-blog');

        if (div_blog !== null) {
            llenarCardsBlog(div_blog, idSoftware); 
        };

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA DEL BLOG
        const page_blog = document.getElementById('pagina-cards-blog');

        if (page_blog !== null) {
            llenarPageBlog(page_blog, idSoftware);  
        };

        iniciaSlider();

        //! Cargando los precios de las diferentes licencias

        const div_precios = document.getElementById('sec-precios');

        if (div_precios) {
            cargarPrecios(idSoftware); 
        }

        //! Llena la información de una noticia del BLOG
        const blogId = getParameterByName('id'); 
        if (blogId !== null) {
            mostrarDetallesBlog(blogId);
        }

        //! Carga el evento para aclarar u obscurecer el background del NAV

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        //! Activa la función para hacer dinámico el fondo del hero de la pantalla, para cambiar entre cada imagen.

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(() => next_fondo(heros), 3000);
        }

        // ! Escuchar el evento de desplazamiento y actualizar la clase 'nav_active'
        // * La cual me sirve para marcar en que sección de la página me encuentro.

        window.addEventListener('scroll', () => updateActiveClass(menuLinks));        

        // ! Actualizar la clase 'nav_active' cuando la página se carga
        window.addEventListener('load', () => updateActiveClass(menuLinks));        

        //! Visualizando los banners de publicidad
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) {
            llena_bannerspub(div_pub);              
        };

        //! Visualizando mensajes informativos en la banda de color del hero
        if (containerElement !== null) {
            updateMessage(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
            cycleMessages(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
        }

        //! Monitoreando los enlaces del botón PLAY para reproducir vídeos de Youtube en el DOM
        const playLink = document.querySelector('.play');
        if (playLink) {
            document.querySelectorAll('.play').forEach(link => {
                link.addEventListener('click', reproduce_video_youtube);
            }); 
        }

        //! Reproduce el vídeo de una reseña
        if ($('.play2').length && $('#ventanaVideo').length && $('#iframeVideo').length) {
            

            $('ul.slide-area').on('click', '.play2', function(event) {            
                event.preventDefault();

                reproduce_testimonio(this);
            });
    
            $('#ventanaVideo').on('hidden.bs.modal', function () {
                $('#iframeVideo').attr('src', '');
            });
        }

        //! Funcionalidad para abrir y cerrar las Pregunta Frecuentes

        // Asegurar que todos los paneles están correctamente inicializados
        $('.accordion-content').hide(); // Oculta todos los paneles
        $('.accordion-button').attr('aria-expanded', 'false'); // Establece todos como no expandidos

        $('.accordion-button').on('click', function() {
            var isExpanded = $(this).attr('aria-expanded') === 'true';
            $(this).attr('aria-expanded', !isExpanded);
            $(this).next('.accordion-content').slideToggle();

            // Cierra los otros elementos si solo uno debe estar abierto a la vez
            if (!isExpanded) {
                $('.accordion-content').not($(this).next()).slideUp();
                $('.accordion-button').not(this).attr('aria-expanded', 'false');
            }
        });

        //! Mantiene actualizado el año en la línea de los Derechos de Autor (PIE DE PÁGINA)
        document.addEventListener('contentIncluded', () => {
            const year = new Date().getFullYear(); // Obtiene el año actual
            const yearElement = document.getElementById('current-year');
            if (yearElement) {
                yearElement.textContent = year; // Establece el año en el elemento span
            }
        });

        //! Obteniendo el link de descarga del software        
        const pasosDescarga = document.getElementById('pasos-para-descargar');

        if (pasosDescarga) {        

            info_Software();
        }

    });

})();